<template>
  <b-card>
    <all-payments-table show-search />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AllPaymentsTable from "@/views/accounting/payments/all-payments/components/AllPaymentsTable.vue";

export default {
  components: {
    BCard,
    AllPaymentsTable,
  },
};
</script>
