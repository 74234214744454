<template>
  <div class="payments-table">
    <!-- SEARCH INPUT -->
    <b-row v-if="showSearch" class="mb-2 d-flex flex-wrap justify-content-end">
      <b-col cols="9" md="auto" class="mb-1 mb-md-0 justify-content-end">
        <b-form-input
          id="filterInput"
          ref="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          debounce="1000"
          autofocus
          :disabled="isBusy"
        />
      </b-col>
    </b-row>

    <!-- PAYMENTS TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          ref="paymentsTable"
          striped
          borderless
          responsive
          class="position-relative"
          :busy.sync="isBusy"
          :per-page="itemsPerPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="fetchPayments"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-img
                id="loading-gif"
                class="my-5"
                fluid-grow
                :src="
                  require('@/assets/foravila/images/gifs/house-loading-1.gif')
                "
              />
            </div>
          </template>

          <!-- Column: sortDate -->
          <template #cell(sortDate)="data">
            <span class="text-nowrap">
              {{ data.item.date }}
            </span>
          </template>

          <!-- Column: localizator -->
          <template #cell(bookingLocalizator)="data">
            <b-booking-localizator
              :localizator="data.value"
              :source="data.item.bookingSource"
              :fictitious="data.item.bookingFictitious"
              :highlighted="data.item.bookingHighlighted"
              :cancelled="data.item.bookingCancelled"
            />
          </template>

          <!-- Column: pvpAmount -->
          <template #cell(pvpAmount)="data">
            <template v-if="data.value || data.value === 0">
              <span v-if="data.value > 0" class="text-success">
                +{{ formatCurrency(data.value) }}
              </span>
              <span v-if="data.value < 0" class="text-danger">
                {{ formatCurrency(data.value) }}
              </span>
              <span v-if="data.value === 0"> - </span>
            </template>
            <span v-else>{{ $t("No definit") }}</span>
          </template>

          <!-- Column: scope -->
          <template #cell(scope)="data">
            <template v-if="data.value === 'SERVICE'">
              {{
                getServiceName(data.item.service, $i18n.locale) ||
                $t("No definit")
              }}
            </template>
            <template v-else>
              {{ getClientPaymentScopeName(data.value, $i18n.locale) }}
            </template>
          </template>

          <!-- Column: method -->
          <template #cell(method)="data">
            {{ getPaymentMethodName(data.value, $i18n.locale) }}
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="data.value === 'CONFIRMED' ? 'success' : 'primary'"
            >
              {{ getClientPaymentStatusName(data.value, $i18n.locale) }}
            </b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!isBusy"
      v-if="paymentsCount > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="itemsPerPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="itemsPerPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  VBTooltip,
  BImg,
  BBadge,
} from "bootstrap-vue";
import {
  getClientPaymentScopeName,
  getClientPaymentStatusName,
  getPaymentMethodName,
  getServiceName,
  notifyError,
} from "@/utils/methods";
import {
  formatCurrency,
  formatDateStringToDatabaseDateTime,
  formatDateStringToDate,
} from "@/utils/formatters";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BBookingLocalizator,
    BImg,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "sortDate",
      sortDesc: true,
      itemsPerPage: 50,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      fields: [
        { key: "sortDate", label: this.$t("Data"), sortable: true },
        { key: "bookingLocalizator", label: this.$t("Nº reserva") },
        { key: "pvpAmount", label: this.$t("Import") },
        { key: "localizator", label: this.$t("Localitzador") },
        { key: "scope", label: this.$t("Àmbit") },
        { key: "method", label: this.$t("Mètode") },
        { key: "status", label: this.$t("Estat") },
      ],
      selectedPayment: null,
      formatDateStringToDate,
      getServiceName,
      getClientPaymentScopeName,
      getClientPaymentStatusName,
      getPaymentMethodName,
      formatCurrency,
    };
  },
  computed: {
    payments() {
      return this.$store.getters["clientPayments/payments"];
    },
    paymentsCount() {
      return this.$store.getters["clientPayments/count"];
    },
  },
  watch: {
    isBusy(busy) {
      if (!busy) {
        this.$nextTick(() => {
          //! this needs to be done in the nextTick because the input
          //! can not be focused if it is disabled, and the input is
          //! disabled while the data is being fetched
          this.$refs.filterInput.focus();
        });
      }
    },
  },
  mounted() {
    this.$refs.filterInput.focus();
  },
  methods: {
    fetchPayments(ctx, callback) {
      this.$store
        .dispatch("clientPayments/fetchFilteredPayments", {
          pagination: true,
          itemsPerPage: ctx.perPage,
          page: ctx.currentPage,
          searchText: ctx.filter || "",
          sort: {
            key: ctx.sortBy,
            order: ctx.sortDesc ? "desc" : "asc",
          },
        })
        .then((response) => {
          callback(this.mapTableClientPaymentsArray(response));
          this.totalRows = this.paymentsCount;
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPayments.title"),
            this.$t("errors.fetchPayments.description")
          )
        );

      return null;
    },
    mapTableClientPaymentsArray(payments) {
      if (!payments.length) return null;
      return payments.map((payment) => ({
        uuid: payment.uuid || this.$t("No definit"),
        sortDate:
          formatDateStringToDatabaseDateTime(payment.date) ||
          this.$t("No definit"),
        date: this.formatDate(payment.date) || this.$t("No definit"),
        pvpAmount: payment.pvpAmount || this.$t("No definit"),
        bookingLocalizator:
          payment.booking?.localizator || this.$t("No definit"),
        bookingSource: payment.booking?.source || this.$t("No definit"),
        bookingHighlighted: payment.booking?.highlighted || false,
        bookingFictitious: payment.booking?.fictitious || false,
        bookingCancelled: payment.booking?.cancelled || false,
        bookingUuid: payment.booking?.uuid || this.$t("No definit"),
        localizator: payment.localizator || this.$t("No definit"),
        scope: payment.scope || this.$t("No definit"),
        service: payment.bookingService?.service || null,
        method: payment.method || this.$t("No definit"),
        status: payment.status || this.$t("No definit"),
      }));
    },
    formatDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
  },
};
</script>

<style lang="scss">
.payments-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
  #loading-gif {
    max-width: 350px;
  }
}
</style>
